<template>
  <div class="my-5">
    <DataLabel>Status</DataLabel>
    <p
      class="text-xl font-extrabold tracking-tight"
      :class="{
        'text-gray-900': !statusMessage.color,
        [statusMessage.color]: statusMessage.color,
      }"
    >
      {{ statusMessage.text }}
    </p>
  </div>
</template>

<script>
import DataLabel from "@/components/DataLabel.vue";

export default {
  components: {
    DataLabel,
  },
  props: {
    status: {
      type: String,
      default: null,
    },
  },
  computed: {
    statusMessage() {
      const messages = {
        "speedtesting": {
          text: "Running speedtest...",
          color: "text-yellow-600",
        },
        "finished-speedtest": {
          text: "Finished speedtest",
          color: "text-green-500",
        },
        "port-checking": {
          text: "Checking open ports",
          color: "text-yellow-500",
        },
        "finished-port-check": {
          text: "Finished port check",
          color: "text-green-500",
        },
      };

      const currentStatus = messages[this.status];

      if (!currentStatus) {
        return {
          text: "Waiting for start",
          color: "text-gray-500",
        };
      }

      return currentStatus;
    },
  },
};
</script>

<style></style>
