<template>
  <div class="text-center p-10">
    <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
    >
      Stim PRO - Bandwidth check
    </h2>

    <start-button @click="start">Start test</start-button>
    <Status :status="status" />

    <DataLabel>Browser</DataLabel>
    <p
      class="mb-4 font-bold"
      :class="{
        'text-red-500': client && client.browser.name !== 'Chrome',
        'text-green-500': client && client.browser.name === 'Chrome',
      }"
    >
      {{ browserInfo }}
    </p>
    <p
      class="mb-4 font-bold text-red-600"
      v-if="client && client.browser.name !== 'Chrome'"
    >
      Unsupported browser detected. Chrome is supported.
    </p>

    <DataLabel>IP Address</DataLabel>
    <p class="mb-4">{{ ip || "..." }}</p>

    <dl class="mt-5 grid grid-cols-2 gap-5">
      <Bandwidth title="Download" :value="download" />
      <Bandwidth title="Upload" :value="upload" />
      <Millisecond title="Ping" :value="ping" />
      <Millisecond title="Jitter" :value="jitter" />
    </dl>

    <PortChecker @finished="finishedPortcheck" />
  </div>
</template>
<script>
import Bowser from "bowser";
import StartButton from "./components/StartButton.vue";
import Millisecond from "./components/Stats/Millisecond.vue";
import Bandwidth from "./components/Stats/Bandwidth.vue";
import Status from "./components/Status.vue";
import DataLabel from "@/components/DataLabel.vue";
import PortChecker from "@/components/PortChecker.vue";

const speedtestServer = {
  name: "Speedtest server",
  server: process.env.VUE_APP_SPEEDTEST_URL,
  dlURL: "backend/garbage.php",
  ulURL: "backend/empty.php",
  pingURL: "backend/empty.php",
  getIpURL: "backend/getIP.php",
};

export default {
  name: "app",
  components: {
    StartButton,
    Millisecond,
    Bandwidth,
    Status,
    DataLabel,
    PortChecker,
  },
  data() {
    return {
      ip: null,
      serverId: null,
      download: null,
      upload: null,
      ping: null,
      jitter: null,
      st: null,
      status: null,
      client: null,
    };
  },
  beforeMount() {
    this.getClient();
    // eslint-disable-next-line
    this.st = new Speedtest();
    this.st.setSelectedServer(speedtestServer);

    this.st.onupdate = (data) => {
      this.download = data.dlStatus;
      this.upload = data.ulStatus;
      this.ping = data.pingStatus;
      this.jitter = data.jitterStatus;
      this.ip = data.clientIp;
    };
    this.st.onend = () => {
      this.status = "port-checking";
      this.$root.$emit("start-port-check");
    };
  },
  methods: {
    start() {
      this.status = "speedtesting";
      this.st.start(); // start the speedtest with default settings
    },
    finishedPortcheck() {
      this.status = "finished-port-check";
    },
    getClient() {
      try {
        this.client = Bowser.parse(window.navigator.userAgent);
      } catch (err) {
        //
      }
    },
  },
  computed: {
    browserInfo() {
      const browser = this.client?.browser;
      if (!browser) return "...";
      return `${browser.name} - ${browser.version}`;
    },
  },
};
</script>
