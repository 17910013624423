<template>
  <h4 class="font-semibold tracking-wider text-gray-600 text-sm">
    <slot />
  </h4>
</template>

<script>
export default {
  name: "DataLabel",
};
</script>

<style></style>
