<template>
  <Wrapper :title="title">
    <dd class="mt-1 text-3xl font-semibold text-gray-900">
      {{ value || "..." }} <span class="text-sm text-gray-500">Mbit/s</span>
    </dd>
  </Wrapper>
</template>

<script>
import Wrapper from "./Wrapper.vue";

export default {
  components: { Wrapper },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>

<style></style>
