<template>
  <button
    type="button"
    @click="$emit('click')"
    class="
      my-6
      inline-flex
      items-center
      px-3
      py-2
      border border-transparent
      text-sm
      leading-4
      font-medium
      rounded-md
      shadow-sm
      text-white
      bg-indigo-600
      hover:bg-indigo-700
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
    "
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "StartButton",
};
</script>

<style></style>
