<template>
  <div class="my-5">
    <DataLabel>Port Checks</DataLabel>

    <div class="flow-root">
      <ul role="list" class="-mb-8">
        <li v-for="check in checkList" :key="check.name">
          <div class="relative pb-8">
            <span
              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            ></span>
            <div class="relative flex space-x-3">
              <div>
                <span
                  class="
                    h-8
                    w-8
                    rounded-full
                    flex
                    items-center
                    justify-center
                    ring-8 ring-white
                  "
                  :class="{
                    'bg-green-500': check.status === 'ok',
                    'bg-gray-500': check.status === 'pending',
                  }"
                >
                  <SvgCheck v-if="check.status === 'ok'" />
                  <SvgExclamation v-if="check.status === 'failed'" />
                  <SvgDots v-if="check.status === 'processing'" />
                </span>
              </div>
              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p class="text-sm text-gray-500">
                    {{ check.name }} -> {{ check.status }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import getPortRange from "@/utils/getPortRange";
import protooClient from "protoo-client";
// import * as mediasoupClient from "mediasoup-client";
import { nanoid } from "nanoid/non-secure";
import DataLabel from "@/components/DataLabel.vue";
import SvgCheck from "@/assets/icons/check.svg";
import SvgExclamation from "@/assets/icons/exclamation.svg";
import SvgDots from "@/assets/icons/dots.svg";
// const portcheckServer = {
//   server: process.env.VUE_APP_PORTCHECK_URL,
// };

export default {
  components: {
    DataLabel,
    SvgCheck,
    SvgExclamation,
    SvgDots,
  },
  data() {
    return {
      checkList: [
        {
          name: "Check webrtc signaling",
          method: "checkWebsocket",
          status: "pending",
        },
        {
          name: "Check turn server",
          method: "checkTurnServer",
          status: "pending",
        },
      ],
    };
  },
  mounted() {
    this.startPortcheck();
  },
  beforeMount() {
    this.$root.$on("start-port-check", this.startPortcheck);
  },
  methods: {
    async startPortcheck() {
      // Check turn server port 3478

      const check = async (listIndex) => {
        const item = this.checkList[listIndex];
        if (!item) return;

        try {
          this.checkList[listIndex].status = "processing";
          await this[item.method]();
          this.checkList[listIndex].status = "ok";
        } catch (err) {
          this.checkList[listIndex].status = "fail";
        } finally {
          check(listIndex + 1);
        }
      };

      await check(0);
    },
    checkWebsocket() {
      new Promise((resolve, reject) => {
        try {
          const getProtooUrl = ({ roomId, peerId, protooHost, protooPort }) =>
            `wss://${protooHost}:${protooPort}/?roomId=${roomId}&peerId=${peerId}`;

          const protooUrl = getProtooUrl({
            roomId: nanoid(),
            peerId: nanoid(),
            protooHost: "sfu.io-tek.no",
            protooPort: 443,
          });
          const protooTransport = new protooClient.WebSocketTransport(
            protooUrl
          );

          const protoo = new protooClient.Peer(protooTransport);
          protoo.on("open", () => {
            protoo.close();
            resolve();
          });
          protoo.on("failed", reject);
        } catch (err) {
          reject();
        }
      });
    },
    checkTurnServer() {
      //
    },
  },
};
</script>

<style></style>
